<script setup>
const props = defineProps(["singleBible"]);
const emit = defineEmits(["select", "goback"]);

const items = computed(
  () => props.singleBible?.location.value?.bibleModel?.booksColl
);
const oldTestColl = computed(() => items.value?.slice(0, 39));
const newTestColl = computed(() => items.value?.slice(39));

function handleSelectBook(bookModel) {
  emit("select", { bookModel });
}
</script>
<template>
  <v-navigation-header
    class="mx-2"
    @goback="emit('goback')"
    label="Книги"
  ></v-navigation-header>

  <div class="books-list-el">
    <div class="books-list-content-el">
      <div class="left ml-4">
        <div class="title">{{ transl("Old testament") }}</div>

        <div
          v-for="book in oldTestColl"
          @click="handleSelectBook(book)"
          class="book-el"
        >
          {{ book.bookNameCanonLong }}
        </div>
      </div>
      <div class="right">
        <div class="title">{{ transl("New testament") }}</div>
        <div
          v-for="book in newTestColl"
          @click="handleSelectBook(book)"
          class="book-el"
        >
          {{ book.bookNameCanonLong }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.books-list-el {
  overflow-y: auto;
  height: 100%;
}

.books-list-content-el {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 140px;
}

.books-list-el .title {
  font-weight: 600;
  font-size: 19px;
  padding: 0;
  margin: 0px 0 5px 0;
  line-height: 40px;
}

.book-el {
  min-height: 40px;
  border-radius: 5px;
  font-size: 15px;
  padding: 0px 8px 0px 8px;
  margin-left: -6px;
  line-height: 40px;
}

.book-el:hover {
  background: var(--root-green);
  color: var(--root-white);
  cursor: pointer;
}

.books-list-el .title {
  position: sticky;
  top: 0;
  background: #fff;
}
</style>
